import {
  BlockStack,
  Box,
  Card,
  Collapsible,
  Icon,
  InlineStack,
  Text,
} from "@shopify/polaris";
import { ChevronUpIcon, ChevronDownIcon } from "@shopify/polaris-icons";
import { RuleI } from "Src/Interface/@Profile";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import { withFallbackText } from "Src/function";

const PolicyRule = ({
  handleToggle,
  opencard,
  ruleData: { policy },
  t,
}: RuleI & DIProps) => {
  return (
    <div className="custom_accordion_Card">
       <div
            onClick={() => handleToggle("policy")}
            style={{ cursor: "pointer" }}
          >
      <Card roundedAbove="sm">
        <BlockStack gap="200">
         
            <InlineStack align="space-between">
              <Text as="h6" variant="headingSm" fontWeight="medium">
                {t("POLICY_TEMPLATE")}
              </Text>
              <Box>
                <Icon
                  source={
                    opencard === "policy" ? ChevronUpIcon : ChevronDownIcon
                  }
                  tone="base"
                />
              </Box>
            </InlineStack>
        
          <Collapsible
            open={opencard === "policy"}
            id="basic-collapsible"
            transition={{
              duration: "300ms",
              timingFunction: "ease-in-out",
            }}
            expandOnPrint
          >
            {policy ? (
              <BlockStack gap={"100"}>
                <InlineStack align="space-between" blockAlign="center">
                  <Text as="strong" variant="bodyMd">
                    {t("NAME")}
                  </Text>
                  <Text as="strong" variant="bodyMd">
                    {withFallbackText(policy?.template_name__policy)}
                  </Text>
                </InlineStack>
              </BlockStack>
            ) : (
              <>{t("NO_PREVIEW_AVAILABLE")}</>
            )}
          </Collapsible>
        </BlockStack>
      </Card>
      </div>
    </div>
  );
};

export default DI(PolicyRule);
