import {
  BlockStack,
  Box,
  Card,
  Divider,
  Layout,
  Page,
  Text,
  TextField,
  useBreakpoints,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import "./createprofile.css";
import { apiUrls } from "Src/Constant/url";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import { useProfileContext } from "./ProfileProvider";
import AdvanceMapping from "./components/AdvanceMapping";
import RulePreview from "./components/RulePreview";
import BasicMapping from "./components/BasicMapping";
import {
  UpdateContextForEdit,
  handleSaveProfile,
} from "./FormatAndSaveProfile";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import GeneralcreateModal from "./components/Modal/GeneralCreateModal";
import WidthPageHeaderCreateProfileSkeleton from "./Skeleton/WidthPageHeaderCreateProfileSkeleton";
import { useSearchParams } from "react-router-dom";

const {
  addNewProfile,
  saveProfile,
  getProfileIdByManualQuery,
  cloneProfile,
  bulkProcess,
} = apiUrls;
interface ExtraPropsForUploadI {
  saveProfileFromUpload?: boolean;
  setSaveProfileFromUpload?: (e: boolean) => void;
  setSaveLoading?: (e: boolean) => void;
}

const CreateProfile = (props: ExtraPropsForUploadI & DIProps) => {
   const {mdUp} = useBreakpoints();
  const { addNewProfileData, profileData, isDIGITAL } = useProfileContext();
  const [searchParams] = useSearchParams();
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const {
    request: { GET, POST },
    navigate,
    t,
    saveProfileFromUpload,
    setSaveProfileFromUpload,
    setSaveLoading,
  } = props;
  const [cnfModal, setCnfModal] = useState({
    open: false,
    payload: {},
    count: 0,
  });
  const profileContext = useProfileContext();
  const { routeData } = useRoutesContext();
  const syncEditData = (data: any, type: "edit" | "clone" | "create") => {
    UpdateContextForEdit(profileContext, data, type);
  };
  const PublishProduct = () => {
    const payload = {
      action_name: "publish-product",
      product_ids: routeData.data.selectedResources.all,
    };
    if (
      routeData.data.profileAtUpload === "yes" &&
      routeData?.data?.selectedResources?.select_all === 1
    ) {
      Object.assign(payload, {
        select_all: routeData?.data?.selectedResources?.select_all,
        filters: routeData?.data?.selectedResources?.filters,
      });
    }
    POST(bulkProcess, payload)
      .then((res) => {
        if (res?.success) {
          navigate("/panel/listings");
          ToastAppBridge(res?.message);
        } else {
          ToastAppBridge(res?.message, { isError: true });
        }
      })
      .finally(() => {
        setSaveLoading?.(false);
        setSaveProfileFromUpload?.(false);
      });
  };
  const saveProfileOverWrite = (overwrite: "yes" | "no") => {
    let flag = true;
    let temPayload: any = structuredClone(cnfModal.payload);
    if (Object.keys(temPayload).length === 0) {
      const payload = handleSaveProfile(
        isDIGITAL,
        t,
        profileContext,
        routeData.data.selectedResources.products_without_profile,
        routeData.data.selectedResources?.select_all,
        routeData.data.selectedResources?.filters
      );
      if (!payload) {
        flag = false;
        setSaveLoading?.(false);
        setSaveProfileFromUpload?.(false);
      } else {
        temPayload = payload;
      }
    }
    if (flag) {
      temPayload["Profile"]["overwrite"] = overwrite;
      POST(saveProfile, temPayload)
        .then((res) => {
          const { success, message } = res;
          if (success) {
            if (routeData.data.profileAtUpload === "yes") {
              PublishProduct();
            } else {
              navigate("/panel/profiling");
            }
            ToastAppBridge(message ?? "Profile created successfully");
          } else {
            setSaveLoading?.(false);
            setSaveProfileFromUpload?.(false);
            ToastAppBridge(message, { isError: true });
          }
        })
        .finally(() => {
          setCnfModal({ open: false, payload: {}, count: 0 });
          profileContext.setsave_loading(false);
        });
    }
  };
  useEffect(() => {
    // redirection for not-new profile creation
    if (routeData.from === undefined && searchParams.get("new") !== "1") {
      navigate("/panel/profiling");
    }
    if (
      // !createModal.reload &&
      hasBeenCalled
    ) {
      setHasBeenCalled(false);
    }
    const params = {};
    /**
     * Type to check Clone Data
     */
    if (
      routeData?.data?.type === "clone" &&
      routeData?.data?.initial?.profile_id &&
      !hasBeenCalled
    ) {
      GET(cloneProfile, { id: routeData.data.initial.profile_id }).then(
        (res) => {
          syncEditData(res.data, "clone");
        }
      );
    }
    /**
     * Type to check Edit Data
     */
    if (
      ["edit", "view"].includes(routeData?.data?.type) &&
      routeData?.data?.initial?.profile_id
    ) {
      Object.assign(params, { id: routeData.data.initial.profile_id });
    }
    GET(addNewProfile, params)
      .then((res) => {
        if (res.success && Object.keys(res?.data ?? {}).length > 0) {
          addNewProfileData(res.data);
          if (
            ["edit", "view"].includes(routeData?.data?.type) &&
            routeData?.data?.initial?.profile_id
          ) {
            syncEditData(res.data, "edit");
          }
        }
      })
      .finally(() => {
        setHasBeenCalled(true);
      });
  }, [routeData.data.initial]);
  useEffect(() => {
    saveProfileFromUpload && saveProfileOverWrite("yes");
  }, [saveProfileFromUpload]);
  if (!hasBeenCalled) {
    return <WidthPageHeaderCreateProfileSkeleton />;
  }
  const ProfileStructure = (
    <BlockStack gap={"400"}>
      <Card>
        <BlockStack gap={"200"}>
          <BlockStack gap={"200"}>
            <div className="custom_required">
              <Text as="h6" variant="headingMd" fontWeight="semibold">
                {t("PROFILING_NAME")}
              </Text>
            </div>
            <Divider />
          </BlockStack>
          <TextField
            disabled={routeData?.data?.type === "view"}
            label=""
            id="profile_code"
            error={profileContext.error.profile_code}
            placeholder={t("ENTER_PROFILE_NAME")}
            value={profileContext.profile.profile_code}
            onChange={(e) => {
              profileContext.updateProfile("profile_code", e);
              if (profileContext.error.profile_code) {
                const tmpError = { ...profileContext.error };
                delete tmpError["profile_code"];
                profileContext.saveError(tmpError);
              }
            }}
            autoComplete="off"
          />
        </BlockStack>
      </Card>
      <BasicMapping />
      {!routeData.data.profileAtUpload && <AdvanceMapping />}
      <GeneralcreateModal />
    </BlockStack>
  );
  if (routeData.data.type === "view" || routeData.data.profileAtUpload) {
    return ProfileStructure;
  }
  return (
    <div>
      
      <Box borderBlockEndWidth={mdUp ? '025' : '0'} borderColor="border">
        <div className="custom_layout_width">
          <Page
            backAction={{
              onAction: () => {
                navigate(-1) ?? navigate("/panel/profiling");
              },
            }}
            fullWidth
            title={t(`PROFILING`)}
            primaryAction={{
              content: t("SAVE"),
              accessibilityLabel: "Save",
              loading: profileContext.save_loading,
              onAction: () => {
                const isRule = profileData?.rule_visible == "0";
                const payload = handleSaveProfile(
                  isDIGITAL,
                  t,
                  profileContext,
                  isRule ? [] : undefined
                );

                if (payload) {
                  if (isRule) {
                    POST(saveProfile, payload)
                      .then((res) => {
                        const { success, message } = res;
                        if (success) {
                          ToastAppBridge(
                            message ?? t("PROFILE_CREATED_SUCCESSFULLY")
                          );
                          navigate("/panel/profiling");
                        } else {
                          ToastAppBridge(message, { isError: true });
                        }
                      })
                      .finally(() => profileContext.setsave_loading(false));
                  } else
                    POST(getProfileIdByManualQuery, payload).then((res) => {
                      const { success, product_count } = res;
                      if (success && product_count === 0) {
                        payload.Profile.overwrite="yes";
                        POST(saveProfile, payload)
                          .then((res) => {
                            const { success, message } = res;
                            if (success) {
                              ToastAppBridge(
                                message ?? t("PROFILE_CREATED_SUCCESSFULLY")
                              );
                              navigate("/panel/profiling");
                            } else {
                              ToastAppBridge(message, { isError: true });
                            }
                          })
                          .finally(() => profileContext.setsave_loading(false));
                      } else {
                        setCnfModal({
                          open: true,
                          payload: payload,
                          count: product_count,
                        });
                        //Open confirmation Modal
                      }
                    });
                }
              },
            }}
            // secondaryActions={[
            //   {
            //     content: `${t("NEED_HELP")} ?`,
            //     accessibilityLabel: "Need Help ?",
            //   },
            // ]}
          ></Page>
        </div>
      </Box>
      <div className="custom_layout_width rule_preview_sticky">
        <Page fullWidth>
          <Layout>
            <Layout.Section> {ProfileStructure}</Layout.Section>
            <Layout.Section variant="oneThird">
              <RulePreview />
            </Layout.Section>
          </Layout>
        </Page>
        <ModalAppBridge
          open={cnfModal.open}
          onHide={() => {
            setCnfModal({ open: false, payload: {}, count: 0 });
            profileContext.setsave_loading(false);
          }}
          body={
            <Box padding="400">
              <Text variant="bodyMd" as="p">
                {t("PRODUCTS_BELONG_TO_ANOTHER_PROFILE", {
                  count: cnfModal.count,
                })}
              </Text>
            </Box>
          }
          footer={
            <>
              <button
                variant="primary"
                onClick={() => saveProfileOverWrite("yes")}
              >
                {t("YES")}
              </button>
              <button onClick={() => saveProfileOverWrite("no")}>
                {t("NO")}
              </button>
            </>
          }
          id="cnf-modal"
          title={t("CONFIRMATION")}
        />
      </div>
    </div>
  );
};

export default DI(CreateProfile);
