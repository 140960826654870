import toast from "react-hot-toast";
import { XSmallIcon, AlertCircleIcon } from "@shopify/polaris-icons";
import { Button, InlineStack, Text } from "@shopify/polaris";

export const ToastAppBridge = (
  message: string,
  obj: { isError?: boolean; duration?: number } = {
    isError: false,
    duration: 10000,
  }
) => {
  try {
    shopify.toast.show(message, obj);
  } catch {
    toast(
      (t) => (
        <InlineStack gap="100" blockAlign="center" direction="row" wrap={false}>
          {obj.isError ? (
            <AlertCircleIcon
              height={20}
              width={20}
              fill="var(--p-color-text-inverse)"
            />
          ) : null}
          <Text
            as="span"
            fontWeight="bold"
            variant="bodyMd"
            tone="text-inverse"
            alignment="center"
          >
            {message}
          </Text>
          <div className="custom-toast-btn">
            <Button
              onClick={() => {
                toast.dismiss(t.id);
              }}
              variant="plain"
              icon={XSmallIcon}
            />
          </div>
        </InlineStack>
      ),
      {
        duration: obj.duration,
        className: `custom-toast ${
          obj.isError ? "custom-toast--error" : "custom-toast--primary"
        }`,
      }
    );
  }
};
