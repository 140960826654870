import { NavMenu } from "@shopify/app-bridge-react";
import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { DI } from "Src/core";
import TemplateContext from "./Templates/components/TemplateContext";
import { RoutesProvider } from "./RoutesProvider";
import Profiling from "./Profilling/ProfilingGrid/Profiling";
import { ProfileProvider } from "./Profilling/CreateProfile/ProfileProvider";
import { ProfileGridProvider } from "./Profilling/ProfilingGrid/ProfileGridProvider";
import Settings from "./Settings/Settings";
import { SettingsProvider } from "./Settings/SettingsProvider";
import { ProductsProvider } from "./Products/ProductsProvider";
import { OrderProvider } from "./Orders/OrderProvider";
import ProductEdit from "./ProductEdit/ProductEdit";
import { ProductEditProvider } from "./ProductEdit/ProductEditProvider";
import OrderView from "./Orders/OrderView";
import ProductView from "./Products/ProductView";
import { ReviewProvider } from "./Review/ReviewProvider";
import FullProfileGridSkeleton from "./Profilling/Skeleton/FullProfileGridSkeleton";
import { BulkEditProvider } from "./BulkEdit/BulkEditProvider";
import { DIProps } from "Src/Interface/@core";
import { Button, InlineStack, Text } from "@shopify/polaris";
import { MenuHorizontalIcon, XIcon } from "@shopify/polaris-icons";
import WithPageHeaderGridSkeleton from "./Review/Skeleton/WithPageHeaderGridSkeleton";
import WithHeaderDashboardSkeleton from "./Dashboard/Skeleton/WithHeaderDashboardSkeleton";
import WithHeaderProductGridSkeleton from "./Products/Skeleton/WithHeaderProductGridSkeleton";
import WithPageHeaderSettingSkeleton from "./Settings/Skeleton/WithPageHeaderSettingSkeleton";
import WithPageHeaderActivitySkeleton from "./Activities/Skeleton/WithPageHeaderActivitySkeleton";
import WithHeaderOrderGridSkeleton from "./Orders/Skeleton/WithHeaderOrderGridSkeleton";
import WidthPageHeaderCreateProfileSkeleton from "./Profilling/CreateProfile/Skeleton/WidthPageHeaderCreateProfileSkeleton";
import WithHeaderCreateTemplateSkeleton from "./Templates/Forms/Skeleton/WithHeaderCreateTemplateSkeleton";
import WithPageHeaderTemplateGridSkeleton from "./Templates/Skeleton/WithPageHeaderTemplateGridSkeleton";
import WithHeaderPricingSkeleton from "./PricingPlan/PricingPlanSkeleton/WithHeaderPricingSkeleton";
import WithHeaderOrderAddOnSkeleton from "./PricingPlan/OrderAddOnSkeleton/WithHeaderOrderAddOnSkeleton";
import WithHeaderBulkEditSkeleton from "./BulkEdit/Skeleton/WithHeaderBulkEditSkeleton";
import WithPageHeaderProductEditSkeleton from "./ProductEdit/Skeleton/WithPageHeaderProductEditSkeleton";
import WithPageHeaderOrderViewSkeleton from "./Orders/view/Skeleton/WithPageHeaderOrderViewSkeleton";
import moment from "moment";
import { apiUrls } from "Src/Constant/url";
import { saveAccountInfo } from "Src/Redux/Slices/dashboardSlice";
import { ToastAppBridge } from "./HelperComponents/Toast";
import { useShopify } from "Src/ShopifyAppBridgeProvider";
import {
  saveCouponEnabled,
  saveCustomSettings,
  saveMetafields,
} from "Src/Redux/Slices/settingsSlice";
import { isConfigChecked } from "Src/function";
import FullPageSkeletonProductImport from "./ProductImport/Skeleton/FullPageSkeletonProductImport";
import FullPageLoader from "./FullPageLoader/FullPageLoader";
const BulkEdit = lazy(() => import("./BulkEdit/BulkEdit"));
const CreateProfile = lazy(
  () => import("./Profilling/CreateProfile/CreateProfile")
);
const Create = lazy(() => import("./Templates/Forms/Create"));
const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
const Templates = lazy(() => import("./Templates/Templates"));
const Products = lazy(() => import("./Products/Products"));
const Orders = lazy(() => import("./Orders/Orders"));
const UploadProducts = lazy(
  () => import("./Products/components/UploadProducts")
);
const Review = lazy(() => import("./Review/Review"));
const Activities = lazy(() => import("./Activities/Activities"));
const PricingPlan = lazy(() => import("./PricingPlan/PricingPlan"));
const OrderAddOnPlans = lazy(
  () => import("./PricingPlan/components/OrderAddOnPlans")
);
const ProductImport = lazy(() => import("./ProductImport"));
const PrivatePanel = ({
  globalState: { get },
  location,
  redux: { auth, settings, dashboard },
  request: { GET },
  dispatch,
  navigate,
  AppBridgeExternalRoute,
  AppBridgeRouting,
}: {} & DIProps) => {
  const isEmbedded = get("isEmbed") === "true";
  const { app } = useShopify();

  const isActive = (keyword: any) => location.pathname.includes(keyword);
  const [isActiveMobileSidebar, setActiveMobileSidebar] = useState(false);

  const sidebarLinkData = useMemo(() => {
    const data = [
      { id: "overview", href: "/panel/overview", title: "Overview" },
      { id: "listings", href: "/panel/listings", title: "Products" },
      { id: "orders", href: "/panel/orders", title: "Orders" },
      { id: "profiling", href: "/panel/profiling", title: "Profiling" },
      { id: "template", href: "/panel/template", title: "Template" },
      { id: "pricing", href: "/panel/pricing", title: "Pricing" },
      { id: "activity", href: "/panel/activity", title: "Activity" },
      { id: "settings", href: "/panel/settings", title: "Settings" },
    ];
    if (settings?.customSettings?.exportReview) {
      data.push({ id: "reviews", href: "/panel/reviews", title: "Reviews" });
    }
    return data;
  }, [auth.isAdmin, settings?.customSettings?.exportReview]);

  const fetchAccountInfo = () => {
    GET(apiUrls.getAccountInfo).then((res) => {
      if (res?.success) {
        const { data } = res;
        dispatch(
          saveAccountInfo({
            activePlan: data?.active_plan,
            billingDate: data?.billing_date,
            activated_on: data?.activated_on,
            orderLimit: data?.order_limit,
            planOrderLimit: data?.plan_order_limit ? Number(data?.plan_order_limit) : null,
            planAmt: data?.plan_amount,
            duration: data?.duration.slice(0, -2),
            productLimit: data?.product_limit,
            ordersUsed: data?.order_consume_limit ?? 0,
            productsUsed: data?.uploadCount,
            clientInfo: data?.client_info,
            type: data?.type
          })
        );
        if (data?.client_info?.options?.login_server?.toLowerCase() === "yii") {
          const getShopifyQuery = get("getShopifyQuery");
          AppBridgeExternalRoute(
            app,
            process.env.REACT_APP_OLD_UI_URL + "?" + getShopifyQuery
          );
        }
        checkValidPlan(data?.client_info?.expire_date);
      } else {
        dispatch(saveAccountInfo({ ...dashboard.accountInfo, ordersUsed: 0 }));
        checkValidPlan();
        ToastAppBridge(res?.message, { isError: true });
      }
    });
  };
  /**
   * checks whether user has a valid plan and navigates to pricing in case he doesn't
   */
  const checkValidPlan = (expiredDate?: string) => {
    if (!expiredDate && !location?.pathname?.includes("pricing")) {
      AppBridgeRouting(app, "/panel/pricing");
      return;
    }
    const diffInDays = moment(expiredDate).diff(moment(), "d");
    if (diffInDays < 0 && !location?.pathname?.includes("pricing")) {
      AppBridgeRouting(app, "/panel/pricing");
    }
  };

  const syncEssentialSettings = () => {
    GET(apiUrls.getConfig).then((res) => {
      if (res?.success) {
        const syncProductEnabled = res?.data?.model?.["sync_product_enable"];
        let cusomSettings = {
          isFetched: true,
          isMetafieldEnabled: false,
          exportReview: false,
          productDeleteOnApp: false,
          syncProductEnabled : isConfigChecked(syncProductEnabled)
        };
        try {
          const parsedCustomSettings = JSON.parse(
            res?.data?.model?.custom_setting
          );
          Object.assign(cusomSettings, {
            isMetafieldEnabled:
              parsedCustomSettings?.metafield_enable &&
              isConfigChecked(parsedCustomSettings?.metafield_enable),
            exportReview:
              parsedCustomSettings?.export_review &&
              isConfigChecked(parsedCustomSettings?.export_review),
            productDeleteOnApp:
              parsedCustomSettings?.product_delete_setting &&
              isConfigChecked(parsedCustomSettings?.product_delete_setting),
          });
        } catch (e) {}
        dispatch(saveCustomSettings(cusomSettings));
        if (res?.data?.model?.metafields) {
          dispatch(saveMetafields(JSON.parse(res?.data?.model?.metafields)));
        }
        if (
          res?.data?.model?.couponEnable &&
          Object.keys(res?.data?.model?.couponEnable).length > 0
        ) {
          const { couponEnable } = res?.data?.model;
          dispatch(
            saveCouponEnabled({
              promoCode: couponEnable?.promo_code ?? "",
              amount: couponEnable?.amount ?? "",
              amountType: couponEnable?.amount_type ?? "",
              billingCycleLimit: couponEnable?.billingCycleLimit ?? "",
            })
          );
        } else {
          dispatch(saveCouponEnabled(undefined));
        }
      }
    });
  };

  useEffect(() => {
    fetchAccountInfo();
    syncEssentialSettings();
  }, []);

  return (
    <>
      {!isEmbedded ? (
        <div className="non_embeded_menu_wrapper">
          <div className="mobile_header">
            <InlineStack align="space-between">
              <Text as="h6" variant="headingMd" fontWeight="bold">
                Logo
              </Text>
              <Button
                icon={MenuHorizontalIcon}
                onClick={() => {
                  setActiveMobileSidebar(true);
                }}
              />
            </InlineStack>
          </div>
          <div
            className={`non_embeded_menu ${
              isActiveMobileSidebar ? "active" : ""
            }`}
          >
            <div className="mobile_menu_header">
              <InlineStack align="space-between">
                <Text as="h6" variant="headingMd" fontWeight="bold">
                  Menu
                </Text>
                <Button
                  icon={XIcon}
                  onClick={() => {
                    setActiveMobileSidebar(false);
                  }}
                />
              </InlineStack>
            </div>
            <NavMenu>
              {sidebarLinkData.map((linkItem) => (
                <a
                  key={linkItem.id}
                  href={linkItem.href}
                  className={isActive(linkItem.id) ? "active" : ""}
                >
                  {linkItem.title}
                </a>
              ))}
            </NavMenu>
          </div>
        </div>
      ) : (
        <NavMenu>
          {sidebarLinkData.map((linkItem) => (
            <a key={linkItem.id} href={linkItem.href}>
              {linkItem.title}
            </a>
          ))}
        </NavMenu>
      )}
      <RoutesProvider>
        <Routes>
          <Route
            path="overview"
            element={
              <Suspense fallback={<WithHeaderDashboardSkeleton />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="listings"
            element={
              <ProductsProvider>
                <Outlet />
              </ProductsProvider>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<WithHeaderProductGridSkeleton />}>
                  {/* <WithHeaderProductGridSkeleton /> */}
                  <Products />
                </Suspense>
              }
            ></Route>
            <Route
              path="upload"
              element={
                <Suspense fallback={<FullPageSkeletonProductImport />}>
                  <UploadProducts />
                </Suspense>
              }
            />
            <Route
              path="bulkedit"
              element={
                <Suspense fallback={<WithHeaderBulkEditSkeleton />}>
                  <BulkEditProvider>
                    <BulkEdit />
                  </BulkEditProvider>
                </Suspense>
              }
            />
            <Route
              path="edit/:productId"
              element={
                <Suspense fallback={<WithPageHeaderProductEditSkeleton />}>
                  <ProductEditProvider>
                    {/* <WithPageHeaderProductEditSkeleton /> */}
                    <ProductEdit />
                  </ProductEditProvider>
                </Suspense>
              }
            />
            <Route
              path="view"
              element={
                <Suspense fallback={<FullPageLoader/>}>
                  <ProductView />
                </Suspense>
              }
            ></Route>
          </Route>
          <Route
            path="product-import"
            element={
              <Suspense fallback={<FullPageSkeletonProductImport />}>
                <ProductImport />
              </Suspense>
            }
          ></Route>
          <Route
            path="profiling"
            element={
              <Suspense fallback={<FullProfileGridSkeleton />}>
                <ProfileGridProvider>
                  <Profiling />
                </ProfileGridProvider>
              </Suspense>
            }
          />
          <Route
            path="profiling/create"
            element={
              <Suspense fallback={<WidthPageHeaderCreateProfileSkeleton />}>
                <ProfileProvider>
                  <CreateProfile />
                </ProfileProvider>
              </Suspense>
            }
          />
          <Route
            path="orders"
            element={
              <Suspense fallback={<WithHeaderOrderGridSkeleton />}>
                <OrderProvider>
                  <Outlet />
                </OrderProvider>
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<WithHeaderOrderGridSkeleton />}>
                  {/* <WithHeaderOrderGridSkeleton /> */}
                  <Orders />
                </Suspense>
              }
            ></Route>
            <Route
              path="view"
              element={
                <Suspense fallback={<WithPageHeaderOrderViewSkeleton />}>
                  <OrderView />
                </Suspense>
              }
            ></Route>
          </Route>
          <Route
            path="template"
            element={
              <Suspense fallback={<WithPageHeaderTemplateGridSkeleton />}>
                <TemplateContext>
                  {/* <WithPageHeaderTemplateGridSkeleton /> */}
                  <Templates />
                </TemplateContext>
              </Suspense>
            }
          />
          <Route
            path="template/create"
            element={
              <Suspense fallback={<WithHeaderCreateTemplateSkeleton />}>
                {/* <WithHeaderCreateTemplateSkeleton /> */}
                <Create />
              </Suspense>
            }
          />
          <Route
            path="pricing"
            element={
              <Suspense fallback={<FullPageLoader/>}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<WithHeaderPricingSkeleton />}>
                  <PricingPlan />
                </Suspense>
              }
            ></Route>
            <Route
              path="add-on-plans"
              element={
                <Suspense fallback={<WithHeaderOrderAddOnSkeleton />}>
                  <OrderAddOnPlans />
                </Suspense>
              }
            ></Route>
          </Route>
          <Route
            path="settings"
            element={
              <Suspense fallback={<WithPageHeaderSettingSkeleton />}>
                <SettingsProvider>
                  <Settings />
                </SettingsProvider>
              </Suspense>
            }
          />
          <Route
            path="activity"
            element={
              <Suspense fallback={<WithPageHeaderActivitySkeleton />}>
                <SettingsProvider>
                  <Activities />
                </SettingsProvider>
              </Suspense>
            }
          />
          {settings?.customSettings?.exportReview ? (
            <Route
              path="reviews"
              element={
                <Suspense fallback={<WithPageHeaderGridSkeleton />}>
                  <ReviewProvider>
                    <Review />
                  </ReviewProvider>
                </Suspense>
              }
            />
            ) : null} 
        </Routes>
      </RoutesProvider>
    </>
  );
};

export default DI(PrivatePanel);
