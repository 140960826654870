import {
  BlockStack,
  Box,
  Card,
  Collapsible,
  Icon,
  InlineStack,
  Text,
} from "@shopify/polaris";
import { ChevronUpIcon, ChevronDownIcon } from "@shopify/polaris-icons";
import { DI } from "Src/core";
import React from "react";
import { RuleI } from "Src/Interface/@Profile";
import { DIProps } from "Src/Interface/@core";

const PriceRule = ({
  opencard,
  handleToggle,
  ruleData,
  t,
}: RuleI & DIProps) => {
  const { price } = ruleData;
  const basePrice = 100;
  let afterRuleApplied = basePrice;
  let afterRoundApplied = afterRuleApplied;
  if (price) {
    if (price?.["custom_price_value"]) {
      const inc_value =
        basePrice * ((price["custom_price_value"] as number) / 100);
      afterRuleApplied += inc_value;
    }
    afterRoundApplied = afterRuleApplied + (price["price-increased"] as number);
  }
  return (
    <div className="custom_accordion_Card">
       <div
            onClick={() => handleToggle("price")}
            style={{ cursor: "pointer" }}
          >
      <Card roundedAbove="sm">
        <BlockStack gap="200">
         
            <InlineStack align="space-between">
              <Text as="h6" variant="headingSm" fontWeight="medium">
                {t("PRICE_TEMPLATE")}
              </Text>
              <Box>
                <Icon
                  source={
                    opencard === "price" ? ChevronUpIcon : ChevronDownIcon
                  }
                  tone="base"
                />
              </Box>
            </InlineStack>
         
          <Collapsible
            open={opencard === "price"}
            id="basic-collapsible"
            transition={{
              duration: "300ms",
              timingFunction: "ease-in-out",
            }}
            expandOnPrint
          >
            {price ? (
              <BlockStack gap={"100"}>
                <InlineStack align="space-between" blockAlign="center">
                  <Text as="strong" variant="bodyMd">
                    {t("NAME")}
                  </Text>
                  <Text as="strong" variant="bodyMd">
                    {(price?.template_name__price)}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between" blockAlign="center">
                  <Text as="strong" variant="bodyMd">
                    {t("COMPARE_AT_PRICE")}
                  </Text>
                  <Text as="strong" variant="bodyMd">
                    {(price?.compare_price)}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between" blockAlign="center">
                  <Text as="strong" variant="bodyMd">
                    {t("PRICE_OF_PRODUCT_LIKE")}
                  </Text>
                  <Text as="strong" variant="bodyMd">
                    {basePrice}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between" blockAlign="center">
                  <Text as="strong" variant="bodyMd">
                    {t("PRICE_RULE")}
                  </Text>
                  <Text as="strong" variant="bodyMd">
                    {price?.price_data}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between" blockAlign="center">
                  <Text as="strong" variant="bodyMd">
                    {t("PRICE_AFTER_RULE_APPLIED")}
                  </Text>
                  <Text as="strong" variant="bodyMd">
                    {afterRuleApplied}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between" blockAlign="center">
                  <Text as="strong" variant="bodyMd">
                    {t("PRICE_ROUNDING")}
                  </Text>
                  <Text as="strong" variant="bodyMd">
                    {price?.["price-applied-type"]}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between" blockAlign="center">
                  <Text as="strong" variant="bodyMd">
                    {t("PRICE_AFTER_ROUND_OFF")}
                  </Text>
                  <Text as="strong" variant="bodyMd">
                    {afterRoundApplied}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between" blockAlign="center">
                  <Text as="strong" variant="bodyMd">
                    {t("FINAL_PRICE_SEND_ON_ETSY")}
                  </Text>
                  <Text as="strong" variant="bodyMd">
                    {afterRoundApplied}
                  </Text>
                </InlineStack>
              </BlockStack>
            ) : (
              <>{t("NO_PREVIEW_AVAILABLE")}</>
            )}
          </Collapsible>
        </BlockStack>
      </Card>
      </div>
    </div>
  );
};

export default DI(PriceRule);
