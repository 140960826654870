import { Trans } from "react-i18next";
import { GlobalState } from "./core/_Services";
import { environment } from "./environment/environment";
import { ObjStrI, ReduxI, SelectOptionI } from "./Interface/@core";
import * as Sentry from "@sentry/react";
import { regexExpressions } from "./Constants";
import moment from "moment";
const query = new URLSearchParams(window.location.search);

const loadScript = () => {
  //Calling AppBridge
  // if (window.self !== window.top || query.get("data[bearer_token]")) {
  //   console.log("shopify :",window.shopify)
  //   const script = document.createElement("script");
  //   script.src = "https://cdn.shopify.com/shopifycloud/app-bridge.js";
  //   script.async = false;
  //   document.head.appendChild(script);
  // }
  const isEnableSentry = (USER_ID: string = "") => {
    if (!environment.isSentry) {
      return false;
    }
    const users = process.env.REACT_APP_SENTRY_USER_ENABLE?.split(",") || [];
    if (users.length === 0) {
      return true;
    }
    return users.includes(USER_ID);
  };
  if (isEnableSentry()) {
    Sentry.init({
      environment: process.env.REACT_APP_ENV_MODE,
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate:
        process.env.REACT_APP_SENTRY_MODE === "production" ? 1.0 : 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};

/**
 * Sets the host in the global state if it is not already set.
 */
const setHost = (): void => {
  let newHost = "";
  try {
    newHost = shopify.config.host as string;
    if (!newHost) {
      const prevHost = GlobalState.get()("host");
      if (prevHost) {
        newHost = prevHost;
      }
    }
    GlobalState.set()("host", newHost);
  } catch {
    const host = query.get("host");
    if (host) {
      GlobalState.set()("host", host);
    }
  }
};

/**
 * Sets the shop in the global state based on the Shopify configuration or URL parameters.
 */
const setShop = (): void => {
  try {
    const shop = shopify.config.shop as string;
    if (shop) {
      GlobalState.set()("shop", shop);
    }
  } catch {
    const shop = query.get("shop");
    if (shop) {
      GlobalState.set()("shop", shop);
    }
  }
};

/**
 * Sets the embedded state in the global state based on the Shopify environment or window context.
 */
const isEmbeddedShopify = (): void => {
  try {
    const embed = shopify.environment.embedded;
    GlobalState.set()("isEmbed", embed);
  } catch {
    console.log("check same window : ",window.self === window.top)
    const isEmbedded = GlobalState.get()("isEmbed");
    // if (isEmbedded === null) {
      const embed = window.self === window.top;
      if (
        window.location.pathname === "/payment/confirmpayment" ||
        window.location.pathname === "/payment/confirm-order-addon-payment"
      ) {
        GlobalState.set()("isEmbed", true);
      } else if(isEmbedded === null) {
        GlobalState.set()("isEmbed", !embed);
      };
    // }
  }
};

/**
 * Initializes the global state by invoking setHost, setShop, and isEmbeddedShopify.
 */
export const selfSeed = (): void => {
  try {
    const shop = shopify.config.shop as string;
    if (shop) {
      const Gshop = GlobalState.get()("shop");
      if (Gshop !== shop) {
        sessionStorage.clear();
      }
    }
  } catch {
    const shop = query.get("shop");
    if (shop) {
      const Gshop = GlobalState.get()("shop");
      if (Gshop !== shop) {
        sessionStorage.clear();
      }
    }
  }

  loadScript();
  setHost();
  setShop();
  isEmbeddedShopify();
};

export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  if (base64Url === undefined) {
    return null;
  }
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

export const convertToStartCase = (str: string) => {
  return str
    .split(" ")
    .map(
      (wrd) => `${wrd.toUpperCase().charAt(0)}${wrd.toLowerCase().substring(1)}`
    )
    .join(" ");
};

/**
 * Validates the authentication token stored in the global state.
 *
 * @param {number} [timeRemaining=15] - The minimum time remaining (in minutes) before the token expires to consider it valid.
 * @returns {boolean} True if the token is valid and has more than the specified time remaining before expiry, false otherwise.
 */
export const validateToken = (timeRemaining: number = 15): boolean => {
  const token = GlobalState.get()("auth_token");
  if (!token) {
    return false;
  }

  const exp = parseJwt(token).exp * 1000;
  const currentTime = Date.now();

  if (exp < currentTime) {
    return false;
  }

  const diffInMilliseconds = exp - currentTime;
  const diffInMinutes = diffInMilliseconds / (1000 * 60);

  if (diffInMinutes < timeRemaining) {
    return false;
  }

  return true;
};
/**
 * creates the pagination label for grids
 * @param param object with count of page, items per page, items' actual length and total
 * @returns string label to display in pagination
 */
export const getPaginationLabel = ({
  perPage,
  page,
  length,
  totalCount,
}: {
  perPage: number;
  page: number;
  length: number;
  totalCount: number;
}) => {
  const prefix =
    length < 2
      ? length
      : `${perPage * (page - 1) + 1}-${perPage * (page - 1) + length}`;
  return <Trans i18nKey="PAGINATION_LABEL" values={{ prefix, totalCount }} />;
};

export const getValueOptionMap = (options: SelectOptionI[]) => {
  return options.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.value]: curr.label,
    }),
    {}
  );
};

export const getFilterRangeLabel = (from?: string, to?: string) => {
  if (from && to) {
    return `${from} - ${to}`;
  } else if (from) {
    return `${from} - Max`;
  } else if (from) {
    return `Min - ${to}`;
  }
  return "";
};

export const updateObjHelper = (
  currentObj: any,
  keys: string[],
  value: string
) => {
  const key = keys[0];
  if (keys.length === 1) {
    currentObj[key] = value;
  } else {
    if (currentObj[key] === undefined) {
      currentObj[key] = {};
    }
    updateObjHelper(currentObj[key], keys.slice(1), value);
  }
};

export const isConfigChecked = (value: number | string) => {
  return value === "1" || value === 1;
};

export const removeInvalidKeys = (obj: ObjStrI) => {
  const copyObj = { ...obj };
  Object.keys(copyObj).forEach((key) => {
    if (
      copyObj?.[key] === "" ||
      copyObj?.[key] === undefined ||
      copyObj?.[key] === null
    ) {
      delete copyObj[key];
    }
  });
  return copyObj;
};
/**
 * creates object as given with key-value pairs given in keys array
 * @param obj object
 * @param keys array of key names
 * @returns object with extracted keys only
 */
export const getPartialObjectFromKeys = (obj: any, keys: string[]) => {
  const mappedObj: any = {};
  keys.forEach((key) => {
    Object.assign(mappedObj, { [key]: obj?.[key] });
  });
  return mappedObj;
};
/**
 * adds or removes any element in array based on its presence
 * @param arr array of string or number
 * @param ele element to be toggled
 * @returns unique array with the element removed or added
 */
export const toggleArrElement = (arr: string[], ele: string) => {
  const temp = [...arr];
  const unique = new Set(temp);
  if (unique.has(ele)) {
    unique.delete(ele);
  } else {
    unique.add(ele);
  }
  return Array.from(unique);
};
/**
 * converts any stringified value in object to parsed
 * @param obj object
 */
export const mapToParsedObj = (obj: any) => {
  return Object.entries(obj)?.reduce((prev: any, curr: any) => {
    let parsed = curr?.[1];
    try {
      parsed = JSON.parse(curr?.[1]);
    } catch (e) {}
    return { ...prev, [curr?.[0]]: parsed };
  }, {});
};

export const getAllCapitalWords = (str: string) => {
  const regex = /\b[A-Z]+\b/g;
  const capitalizedWords = str.match(regex);
  return capitalizedWords || [];
};

export const modifyAttributes = (attributes: any[]) => {
  return attributes.map((attr) => ({
    ...attr,
    possible_values: attr?.possible_values
      ? JSON.parse(attr?.possible_values)
      : null,
    selected_values: attr?.selected_values
      ? JSON.parse(attr?.selected_values)
      : null,
    scales: attr?.scales ? JSON.parse(attr?.scales) : null,
  }));
};
/**
 * fills any empty type values in object
 * @param obj the object to be filled
 * @param emptyValObj object with placeholder values
 * @returns object filled with placeholder values for empty type keys
 */
export const fillEmptyValues = (
  obj: { [key: string]: any },
  emptyValObj?: ObjStrI
) => {
  return Object.entries(obj)?.reduce((prev: any, curr: any) => {
    const [key, val] = curr;
    return { ...prev, [key]: val ? val : emptyValObj?.[key] ?? "N/A" };
  }, {});
};

export const addPrefixFilters = (filters: ObjStrI, prefix: string) => {
  return Object.entries(filters).reduce((prev: ObjStrI, curr: string[]) => {
    return { ...prev, [`${prefix}${curr[0]}`]: curr[1] };
  }, {});
};

export const getOptionsFromObj = (obj: ObjStrI, reverse: boolean = false) => {
  return Object.entries(obj).map(([key, val]) => {
    if (reverse) {
      return { label: key, value: val };
    }
    return { label: val, value: key };
  });
};

export const getOptionsFromAssociateObj = (obj: { [key: string]: ObjStrI }) => {
  return Object.entries(obj).map(([key, valObj]) => {
    return { title: key, options: getOptionsFromObj(valObj) };
  });
};

export const removeUncheckConfigKeys = (
  obj: ObjStrI = {},
  addedKey: string
) => {
  const result = { ...obj };
  if (addedKey in result && isConfigChecked(result[addedKey])) {
    delete result[addedKey];
  } else {
    Object.assign(result, { [addedKey]: "1" });
  }
  return result;
};
/**
 * get key names ectracted from filter keys
 * @param key name of key
 * @returns array of keys extracted
 */
export const getEnclosedKeyNames = (key: string) => {
  return key
    .split("]")
    .map((item) => {
      const startIndex = item.indexOf("[");
      if (startIndex > -1) {
        return item.substring(startIndex + 1, item.length);
      }
      return null;
    })
    .filter((item) => item !== null);
};
/**
 * converts filter key names to nested object
 * @param obj returns nested object
 * @returns nested object
 */
export const convertFiltersStrToNested = (obj: ObjStrI) => {
  return Object.entries(obj).reduce((prev: any, [key, value]) => {
    const enclosedKeyNames = getEnclosedKeyNames(key);
    let obj: any = {};
    let traverseObj: any = value;
    const reverseEnclosedKeyNames = [...enclosedKeyNames];
    reverseEnclosedKeyNames.reverse().forEach((keyName) => {
      if (keyName === "") {
        obj = traverseObj.split(",");
        traverseObj = obj;
      } else {
        obj = { [keyName]: traverseObj };
        traverseObj = { ...obj };
      }
    });
    let finalObj = {
      ...prev,
    };
    if (prev?.[enclosedKeyNames[0]]) {
      finalObj[enclosedKeyNames[0]] = {
        ...finalObj[enclosedKeyNames[0]],
        ...obj[enclosedKeyNames[0]],
      };
    } else {
      finalObj = { ...finalObj, ...obj };
    }
    return finalObj;
  }, {});
};

export const isValidValue = (val: string | number) =>
  val !== undefined && val !== null && val?.toString()?.trim() !== "";

export const hasAnyValidValue = (valArr: any[]) => {
  return valArr.some((val) => isValidValue(val));
};
// adds fallback text "N/A" for any invalid value
export const withFallbackText = (val: string) => {
  return isValidValue(val) ? val : "N/A";
}

export const isValidNumericVal = (
  val: string,
  allowDecimal: boolean = false
) => {
  if (allowDecimal) {
    return regexExpressions.NUMBER_CHECK_WITH_DOT.test(val) || val === "";
  }
  return regexExpressions.NUMBER_WITHOUT_DOT.test(val) || val === "";
};

export const deepCloneObj = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

export const getFormattedDateTime = (
  dateStr: string | Date | undefined,
  includeTime: boolean = false
) => {
  if (!dateStr || dateStr.toString().trim() === "") {
    return "--";
  }

  if (includeTime) {
    return moment(dateStr).format("DD-MM-YYYY HH:mm:ss");
  }
  return moment(dateStr).format("DD-MM-YYYY");
};
/**
 * get details of activity redirection actions
 * @param href link from activity item
 * @returns object with redirection link and type
 */
export const getActivityRedirectionObj = (href: string) => {
  const queryParams = new URLSearchParams(href.split("?")?.[1]);
  const separator = "amp;";
  let paramObj: any = {};
  for (var value of queryParams.keys()) {
    paramObj[value.replace(separator, "")] = queryParams.get(value);
  }
  let redirectionLink = href;
  let redirectionType: "internal" | "external" = "external";

  if (paramObj?.["EtsyListingSearch[mapping_status]"]) {
    let selectedTab;
    switch (paramObj?.["EtsyListingSearch[mapping_status]"]) {
      case "close match":
      case "not linked":
        selectedTab = 1;
        break;
      case "linked":
        selectedTab = 2;
        break;
    }
    redirectionLink = `/panel/listings?selectedTab=${selectedTab}`;
    redirectionType = "internal";
  } else if (paramObj?.file && paramObj?.path) {
    let pathPrefix = paramObj.path;
    if (paramObj.path.includes("marketplace-integration")) {
      pathPrefix = paramObj.path.split("marketplace-integration")?.[1];
    }
    redirectionLink = `${process.env.REACT_APP_END_POINT}${process.env.REACT_APP_DOWNLOAD_CSV}?path=${pathPrefix}&file=${paramObj?.file}`;
    redirectionType = "external";
  } else if (href.includes("/payment/index")) {
    redirectionLink = `/panel/pricing`;
    redirectionType = "internal";
  }
  return { redirectionType, redirectionLink };
};

export const getTranslationKey = (words: string) => {
  return words
    ? words
        .split(" ")
        .map((word) => word.toUpperCase())
        .join("_")
    : "";
};

export const getInAppExternalLink = (redux: ReduxI, inAppRoute: string) => {
  const shopName = redux.userDetails?.shopifyShop?.myshopify_domain?.replace(
    ".myshopify.com",
    ""
  );
  const isEmbeddedApp = GlobalState.get()("isEmbed") === "true";
  if (isEmbeddedApp) {
    return `https://admin.shopify.com/store/${shopName}/apps/${process.env.REACT_APP_SHOPIFY_APP_NAME}${inAppRoute}`;
  } else {
    return `${inAppRoute}`;
  }
};