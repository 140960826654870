import {
  Banner,
  BlockStack,
  Box,
  Card,
  Divider,
  Grid,
  IndexTable,
  InlineGrid,
  InlineStack,
  Layout,
  Text,
  useBreakpoints,
} from "@shopify/polaris";
import { DI } from "Src/core";

import { GetBadgeForStatus } from "../components/AllOrders";
import { DIProps, ObjStrI } from "Src/Interface/@core";
import { getShopifyBadge } from "Src/Component/Products/function";
import ShopifyOrderViewSkeleton from "./Skeleton/ShopifyOrderViewSkeleton";
import { getFormattedDateTime, isValidValue } from "Src/function";

const ShopifyView = ({
  hasBeenLoading,
  orderData,
  t,
}: DIProps & { orderData: any; hasBeenLoading: boolean }) => {
  const { mdUp } = useBreakpoints();
  const { shopify_order_data } = orderData;

  const shippingLine = shopify_order_data?.shipping_lines;
  const taxLines = shopify_order_data?.tax_lines;
  const currency = shopify_order_data?.currency;
  const cancelled_at = shopify_order_data?.cancelled_at;
  const noteAttribute = shopify_order_data?.note_attributes;
  const fulfillement = shopify_order_data?.fulfillments;
  const status = shopify_order_data?.financial_status;
  const rowMarkupShopifyItem = shopify_order_data?.line_items?.map(
    (
      {
        title,
        id,
        price,
        sku,
        quantity,
        total_discount,
        fulfillment_status,
        properties,
      }: any,
      index: number
    ) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <BlockStack gap={"0"}>
            <Text variant="bodyMd" fontWeight="medium" as="span">
              {title}
            </Text>
            {/* <Text variant="bodyMd" fontWeight="regular" as="span">
              Weight: -
            </Text> */}
            <Text variant="bodyMd" fontWeight="regular" as="span">
              {t("LISTING_ID")}: {id}
            </Text>
            <Text variant="bodyMd" fontWeight="regular" as="span">
              {t("SKU")}: {sku || "-"}
            </Text>
            {properties
              ? properties?.map?.((property: ObjStrI) => (
                  <Text variant="bodyMd" fontWeight="regular" as="span">
                    {property?.name}:{" "}
                    {property?.value || "-"}
                  </Text>
                ))
              : null}
          </BlockStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {currency + " " + price + "*" + quantity}
        </IndexTable.Cell>
        <IndexTable.Cell>{currency + " " + total_discount}</IndexTable.Cell>
        <IndexTable.Cell>
          {price * quantity + parseInt(total_discount)}
          {/* {price + total_discount} */}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {getShopifyBadge(getFullfillmentStatus(fulfillment_status),t)}
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const rowMarkupShopifyItemMobile = shopify_order_data?.line_items?.map(
    (
      {
        title,
        id,
        price,
        sku,
        quantity,
        total_discount,
        fulfillment_status,
        properties,
      }: any,
      index: number
    ) => (
      <Box id={id} key={id} >
        <Box padding="300" >
          <BlockStack gap="200">
            <BlockStack gap={"0"}>
              <Text variant="bodyMd" fontWeight="medium" as="span">
                {title}
              </Text>

              <Text variant="bodyMd" fontWeight="regular" as="span">
                {t("LISTING_ID")}: {id}
              </Text>
              <Text variant="bodyMd" fontWeight="regular" as="span">
                {t("SKU")}: {sku || "-"}
              </Text>
              {properties
                ? properties?.map?.((property: ObjStrI) => (
                    <Text variant="bodyMd" fontWeight="regular" as="span">
                      {property?.name}:{" "}
                      {property?.value || "-"}
                    </Text>
                  ))
                : null}
            </BlockStack>

            <Divider />

            <InlineGrid columns={2}>
              <Text variant="bodyMd" as="p" tone="subdued">
                {" "}
                {t("SELLING_PRICE")}:{" "}
              </Text>
              <Text variant="bodyMd" as="p" tone="subdued">
                {currency + " " + price + "*" + quantity}
              </Text>
            </InlineGrid>

            <InlineGrid columns={2}>
              <Text variant="bodyMd" as="p" tone="subdued">
                {" "}
                {t("DISCOUNT")}:{" "}
              </Text>
              <Text variant="bodyMd" as="p" tone="subdued">
                {currency + " " + total_discount}
              </Text>
            </InlineGrid>

            <InlineGrid columns={2}>
              <Text variant="bodyMd" as="p" tone="subdued">
                {" "}
                {t("LINE_ITEM_STATUS")}:{" "}
              </Text>
              <Text variant="bodyMd" as="p" tone="subdued">
                {getShopifyBadge(getFullfillmentStatus(fulfillment_status),t)}
              </Text>
            </InlineGrid>

            <Divider />

            <InlineGrid columns={2}>
              <Text variant="bodyMd" as="p" fontWeight="medium">
                {" "}
                {t("FINAL_PRICE")}:{" "}
              </Text>
              <Text variant="bodyMd" as="p" tone="subdued">
                {price * quantity + parseInt(total_discount)}
              </Text>
            </InlineGrid>
          </BlockStack>
          </Box>
          <Box borderBlockEndWidth='025' borderColor="border"></Box>
      </Box>
    )
  );

  function getTaxIncluded() {
    if (shopify_order_data.taxes_included) {
      return "(included)";
    }
  }

  function getFullfillmentStatus(status: any) {
    if (status === "fulfilled") {
      return "Fulfilled";
    } else if (status === "partial") {
      return "Partial-Fulfilled";
    } else {
      return "Unfulfilled";
    }
  }

  if (isValidValue(shopify_order_data?.errors)) {
    return (
      <Banner tone="critical">
        <Text as="p">{t("ERROR_FROM_SHOPIFY")} : {shopify_order_data?.errors ?? t("DETAILS_NOT_FOUND")}</Text>
      </Banner>
    );
  }

  if (!hasBeenLoading) {
    return <ShopifyOrderViewSkeleton />;
  }
  return (
    <div className={`${mdUp ? "" : "mobile_grid_no_bulk_actions"}`}>
      <Layout>
        <Layout.Section>
          <BlockStack gap={"300"}>
            <Card>
              <BlockStack gap={"300"}>
                <InlineStack gap={"200"} blockAlign="center">
                  <Text as="strong" variant="bodySm" fontWeight="medium">
                    {shopify_order_data?.name}
                  </Text>
                  {GetBadgeForStatus(status,t)}
                  {shopify_order_data?.line_items?.map((e: any) =>
                    getShopifyBadge(getFullfillmentStatus(e.fulfillment_status),t)
                  )}
                  {cancelled_at && getShopifyBadge("canceled",t)}
                </InlineStack>
                <Divider />
                <BlockStack gap={"200"}>
                  <Text as="strong" variant="headingSm" fontWeight="bold">
                    {t("ORDERED_PRODUCT")} (
                    {shopify_order_data?.line_items?.length})
                  </Text>
                  <Card padding={"0"} roundedAbove="xs">
                   {mdUp ? <IndexTable
                      selectable={false}
                      itemCount={2}
                      headings={[
                        { title: t("TITLE") },
                        { title: t("SELLING_PRICE") },
                        { title: t("DISCOUNT") },
                        { title: t("FINAL_PRICE") },
                        { title: t("LINE_ITEM_STATUS") },
                      ]}
                    >
                      {rowMarkupShopifyItem }
                    </IndexTable>
                   : rowMarkupShopifyItemMobile  
                  }
                  </Card>
                </BlockStack>
              </BlockStack>
            </Card>

            <Card>
              <BlockStack gap={mdUp ? "400" : "300"}>
                <Text as="strong" variant="headingSm" fontWeight="bold">
                  {t("ORDER_TOTAL")}
                </Text>
                {!mdUp && <Divider />}
                {mdUp ? (
                  <BlockStack gap={"200"}>
                    <div className="sp-order_details">
                      <InlineStack align="space-between" blockAlign="center">
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("DISCOUNT")}:
                        </Text>
                        <div className="sp-order_details_c">
                          <Text
                            as="span"
                            tone="subdued"
                            variant="bodyMd"
                            fontWeight="medium"
                            alignment="start"
                          >
                            {t("CSTM_DISCOUNT")}
                          </Text>
                        </div>
                        <BlockStack gap={"200"}>
                          <Text as="span" variant="bodyMd" fontWeight="regular">
                            {shopify_order_data?.current_total_discounts}
                          </Text>
                        </BlockStack>
                      </InlineStack>
                    </div>
                    <div className="sp-order_details">
                      <InlineStack align="space-between" blockAlign="center">
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("SUBTOTAL")}:
                        </Text>
                        <div className="sp-order_details_c">
                          <Text
                            as="span"
                            tone="subdued"
                            variant="bodyMd"
                            fontWeight="medium"
                            alignment="start"
                          >
                            {shopify_order_data?.line_items?.length} items
                          </Text>
                        </div>
                        <BlockStack gap={"200"}>
                          <Text as="span" variant="bodyMd" fontWeight="regular">
                            {shopify_order_data?.current_subtotal_price}
                          </Text>
                        </BlockStack>
                      </InlineStack>
                    </div>
                    <div className="sp-order_details">
                      <InlineStack align="space-between" blockAlign="start">
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("SHIPPING")}
                        </Text>
                        <div className="sp-order_details_c">
                          <BlockStack gap={"200"}>
                            {shippingLine?.map((item: any) => {
                              return (
                                <Text
                                  as="span"
                                  tone="subdued"
                                  variant="bodyMd"
                                  fontWeight="medium"
                                  alignment="start"
                                >
                                  {item.code}
                                </Text>
                              );
                            })}
                          </BlockStack>
                        </div>
                        <BlockStack gap={"200"}>
                          {shippingLine?.map((item: any) => {
                            return (
                              <Text
                                as="span"
                                variant="bodyMd"
                                fontWeight="regular"
                              >
                                {currency + " " + item.price}
                              </Text>
                            );
                          })}
                        </BlockStack>
                      </InlineStack>
                    </div>
                    <div className="sp-order_details">
                      <InlineStack align="space-between" blockAlign="center">
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("TOTAL_TAX")}:
                        </Text>
                        <div className="sp-order_details_c">
                          <BlockStack gap={"200"}>
                            {taxLines?.map((item: any) => {
                              return (
                                <Text
                                  as="span"
                                  tone="subdued"
                                  variant="bodyMd"
                                  fontWeight="medium"
                                  alignment="start"
                                >
                                  {item.title} {item.rate * 100 + "%"}{" "}
                                  {getTaxIncluded()}
                                </Text>
                              );
                            })}
                          </BlockStack>
                        </div>
                        <BlockStack gap={"200"}>
                          {taxLines?.map((item: any) => {
                            return (
                              <Text as="span" variant="bodySm">
                                {currency + " " + item.price}
                              </Text>
                            );
                          })}
                        </BlockStack>
                      </InlineStack>
                    </div>
                  </BlockStack>
                ) : (
                  <BlockStack gap={"200"}>
                    <InlineStack align="space-between" blockAlign="end">
                      <BlockStack>
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("DISCOUNT")}:
                        </Text>

                        <Text
                          as="span"
                          tone="subdued"
                          variant="bodyMd"
                          fontWeight="medium"
                          alignment="start"
                        >
                          {t("CSTM_DISCOUNT")}
                        </Text>
                      </BlockStack>
                      <Text as="span" variant="bodyMd" fontWeight="regular">
                        {shopify_order_data?.current_total_discounts}
                      </Text>
                    </InlineStack>

                    <InlineStack align="space-between" blockAlign="end">
                      <BlockStack>
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("SUBTOTAL")}:
                        </Text>

                        <Text
                          as="span"
                          tone="subdued"
                          variant="bodyMd"
                          fontWeight="medium"
                          alignment="start"
                        >
                          {shopify_order_data?.line_items?.length} items
                        </Text>
                      </BlockStack>
                      <BlockStack gap={"200"}>
                        <Text as="span" variant="bodyMd" fontWeight="regular">
                          {shopify_order_data?.current_subtotal_price}
                        </Text>
                      </BlockStack>
                    </InlineStack>

                    <InlineStack align="space-between" blockAlign="end">
                      <BlockStack>
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("SHIPPING")}
                        </Text>

                        <BlockStack gap={"100"}>
                          {shippingLine?.map((item: any) => {
                            return (
                              <Text
                                as="span"
                                tone="subdued"
                                variant="bodyMd"
                                fontWeight="medium"
                                alignment="start"
                              >
                                {item.code}
                              </Text>
                            );
                          })}
                        </BlockStack>
                      </BlockStack>

                      <BlockStack gap={"100"}>
                        {shippingLine?.map((item: any) => {
                          return (
                            <Text
                              as="span"
                              variant="bodyMd"
                              fontWeight="regular"
                            >
                              {currency + " " + item.price}
                            </Text>
                          );
                        })}
                      </BlockStack>
                    </InlineStack>

                    <InlineStack align="space-between" blockAlign="end">
                      <BlockStack>
                        <Text
                          as="strong"
                          variant="headingSm"
                          fontWeight="medium"
                        >
                          {t("TOTAL_TAX")}:
                        </Text>

                        <BlockStack gap={"100"}>
                          {taxLines?.map((item: any) => {
                            return (
                              <Text
                                as="span"
                                tone="subdued"
                                variant="bodyMd"
                                fontWeight="medium"
                                alignment="start"
                              >
                                {item.title} {item.rate * 100 + "%"}{" "}
                                {getTaxIncluded()}
                              </Text>
                            );
                          })}
                        </BlockStack>
                      </BlockStack>
                      <BlockStack gap={"100"}>
                        {taxLines?.map((item: any) => {
                          return (
                            <Text as="span" variant="bodySm">
                              {currency + " " + item.price}
                            </Text>
                          );
                        })}
                      </BlockStack>
                    </InlineStack>
                  </BlockStack>
                )}
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap={"300"}>
            <Card>
              <BlockStack gap={mdUp ? "400" : "300"}>
                <Text as="strong" variant="headingSm" fontWeight="bold">
                  {t("ORDER_INFO")}
                </Text>
                {!mdUp && <Divider />}
                <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                  <Text as="strong" variant="headingSm" fontWeight="medium">
                    {t("CREATION_DATE")}:
                  </Text>
                  <Text as="span" variant="bodyMd" fontWeight="regular">
                    {getFormattedDateTime(shopify_order_data?.created_at, true)}
                  </Text>
                </Grid>
                <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                  <Text as="strong" variant="headingSm" fontWeight="medium">
                    {t("PROCESSING_DATE")}:
                  </Text>
                  <Text as="span" variant="bodyMd" fontWeight="regular">
                    {getFormattedDateTime(
                      shopify_order_data?.processed_at,
                      true
                    )}
                  </Text>
                </Grid>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={mdUp ? "400" : "300"}>
                <Text as="strong" variant="headingSm" fontWeight="bold">
                  {t("SHIPPING_DETAILS")}:
                </Text>
                {!mdUp && <Divider />}
                <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                  <Text as="strong" variant="headingSm" fontWeight="medium">
                    {t("ADDRESS")}
                  </Text>
                  <Text as="span" variant="bodyMd" fontWeight="regular">
                    {shopify_order_data?.shipping_address?.country},
                    {shopify_order_data?.shipping_address?.country_code}
                  </Text>
                </Grid>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={mdUp ? "400" : "300"}>
                <Text as="strong" variant="headingSm" fontWeight="bold">
                  {t("NOTE_ATTRIBUTES")}
                </Text>
                {!mdUp && <Divider />}
                {noteAttribute?.map((item: any) => {
                  return (
                    <Grid columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}>
                      <Text as="strong" variant="headingSm" fontWeight="medium">
                        {item.name}
                      </Text>
                      <Text as="span" variant="bodyMd" fontWeight="regular">
                        {item.value}
                      </Text>
                    </Grid>
                  );
                })}
              </BlockStack>
            </Card>
            {fulfillement?.length > 0 ? (
              <Card>
                <BlockStack gap={mdUp ? "400" : "300"}>
                  <Text as="strong" variant="headingSm" fontWeight="bold">
                    {t("TRACKING_DETAILS")}
                  </Text>
                  {!mdUp && <Divider />}
                  {fulfillement?.map((item: any, index: number) => {
                    return (
                      <>
                        <BlockStack gap={"300"}>
                          <BlockStack gap={"300"}>
                            <Grid
                              columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}
                            >
                              <Text
                                as="strong"
                                variant="headingSm"
                                fontWeight="medium"
                              >
                                {t("TRACKING_COMPANY")}:
                              </Text>
                              <Text
                                as="span"
                                variant="bodyMd"
                                fontWeight="regular"
                              >
                                {item["tracking_company"]}
                              </Text>
                            </Grid>

                            <Grid
                              columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}
                            >
                              <Text
                                as="strong"
                                variant="headingSm"
                                fontWeight="medium"
                              >
                                {t("TRACKING_NUMBER")}:
                              </Text>
                              <Text
                                as="span"
                                variant="bodyMd"
                                fontWeight="regular"
                              >
                                {item["tracking_number"]}
                              </Text>
                            </Grid>
                            <Grid
                              columns={{ sm: 2, lg: 2, md: 2, xl: 2, xs: 2 }}
                            >
                              <Text
                                as="strong"
                                variant="headingSm"
                                fontWeight="medium"
                              >
                                {t("TRACING_URL")}:
                              </Text>
                              <div className="break_word">
                                <Text
                                  as="span"
                                  variant="bodyMd"
                                  fontWeight="regular"
                                >
                                  {item["tracking_url"]}
                                </Text>
                              </div>
                            </Grid>
                          </BlockStack>
                          {fulfillement.length - 1 > index && <Divider />}
                        </BlockStack>
                        {index + 1 < fulfillement?.length ? (
                          <Divider />
                        ) : null}
                      </>
                    );
                  })}
                </BlockStack>
              </Card>
            ) : null}
          </BlockStack>
        </Layout.Section>
      </Layout>
    </div>
  );
};

export default DI(ShopifyView);
