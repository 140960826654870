/* eslint-disable react-hooks/exhaustive-deps */
import { BlockStack, Card, Text } from "@shopify/polaris";
import { DI } from "Src/core";
import React, { useMemo, useState } from "react";
import { apiUrls } from "Src/Constant/url";
import { DIProps } from "Src/Interface/@core";
import { useProfileContext } from "../ProfileProvider";
import PriceRule from "./RulesComponents/PriceRule";
import ShippingRule from "./RulesComponents/ShippingRule";
import InventoryRule from "./RulesComponents/InventoryRule";
import PolicyRule from "./RulesComponents/PolicyRule";
import { RuleDataI } from "Src/Interface/@Profile";

const { getTemplateRuleProfile } = apiUrls;
const RulePreview = ({ request: { GET }, t }: DIProps) => {
  const {
    templates: {
      inventory_templates,
      policy_templates,
      price_templates,
      shipping_templates,
    },
  } = useProfileContext();
  const [ruleData, setRuleData] = useState<RuleDataI>({
    inventory: undefined,
    shipping: undefined,
    policy: undefined,
    price: undefined,
  });
  const [opencard, setOpencard] = useState<keyof RuleDataI | undefined>(
    undefined
  );

  const getTemplatesDataAndSave = (
    code: string,
    localCode: keyof RuleDataI
  ) => {
    if (code !== "") {
      GET(getTemplateRuleProfile, {
        template_id: code,
        template_type: localCode,
      }).then((res) => {
        const { success, data } = res;
        if (success) {
          setOpencard(localCode);
          setRuleData((prev) => ({ ...prev, [localCode]: data }));
        }
      });
    }
  };
  useMemo(() => {
    getTemplatesDataAndSave(inventory_templates, "inventory");
  }, [inventory_templates]);
  useMemo(() => {
    getTemplatesDataAndSave(shipping_templates, "shipping");
  }, [shipping_templates]);
  useMemo(() => {
    getTemplatesDataAndSave(price_templates, "price");
  }, [price_templates]);
  useMemo(() => {
    getTemplatesDataAndSave(policy_templates, "policy");
  }, [policy_templates]);

  const handleToggle = (code: keyof RuleDataI) => {
    setOpencard(opencard === code ? undefined : code);
  };
  const RulesProps = { opencard, ruleData, handleToggle };
  return (
    <div className="custom_card_profile">
      <Card>
        <BlockStack gap={"200"}>
          <Text as="h6" variant="headingMd" fontWeight="semibold">
            {t("RULE_PREVIEW")}
          </Text>
          <ShippingRule {...RulesProps} />
          <PolicyRule {...RulesProps} />
          <InventoryRule {...RulesProps} />
          <PriceRule {...RulesProps} />
        </BlockStack>
      </Card>
    </div>
  );
};

export default DI(RulePreview);
