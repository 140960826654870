import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AccountDetailsI,
  NotificationsI,
  ObjStrI,
  SettingsI,
  ShopifyDetailsI,
} from "Src/Interface/@core";

const initialState: SettingsI = {
  accountDetails: {
    paymentHistory: {},
  },
  notifications: {
    failed_order: "0",
    order: "0",
    subscription_plan: "0",
    order_threshold: {
      On_Exceeding_100: "0",
      On_Exceeding_95: "0",
      On_Exceeding_80: "0",
    },
  },
  shopifyDetails: {
    currency: "",
    email: "",
    owner_name: "",
    preferred_time: "",
    shopify_country: "",
    shopify_plan: "",
    shopurl: "",
    time_zone: "",
  },
  locationMap: null,
  customSettings: {
    isMetafieldEnabled: false,
    exportReview: false,
    isFetched: false,
    productDeleteOnApp: false,
    syncProductEnabled: false,
  },
  metafields: [],
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    saveAccountDetails: (state, action: PayloadAction<AccountDetailsI>) => {
      state.accountDetails = { ...action.payload };
    },
    saveNotifications: (state, action: PayloadAction<NotificationsI>) => {
      state.notifications = { ...action.payload };
    },
    saveShopifyDetails: (state, action: PayloadAction<ShopifyDetailsI>) => {
      state.shopifyDetails = action.payload;
    },
    saveLocationMap: (state, action: PayloadAction<ObjStrI>) => {
      state.locationMap = action.payload;
    },
    saveCustomSettings: (
      state,
      action: PayloadAction<SettingsI["customSettings"]>
    ) => {
      state.customSettings = { ...action.payload };
    },
    saveMetafields: (state, action: PayloadAction<string[]>) => {
      state.metafields = [...action.payload];
    },
    saveCouponEnabled: (
      state,
      action: PayloadAction<SettingsI["couponEnabled"]>
    ) => {
      state.couponEnabled = action.payload;
    },
  },
});

export const {
  saveAccountDetails,
  saveNotifications,
  saveShopifyDetails,
  saveLocationMap,
  saveCustomSettings,
  saveMetafields,
  saveCouponEnabled
} = settingsSlice.actions;

export default settingsSlice.reducer;