import { BlockStack, Card, InlineStack, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'

const ConnectedShopSkeleton = () => {
    return (
        <Card roundedAbove='xs'>
            <BlockStack gap="400">
                <InlineStack align="space-between" blockAlign='center'>
                    <InlineStack gap="100" blockAlign='center'>
                        <div style={{ "width": "20px" }} className='skeleton-height-20'>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <div style={{ "width": "80px" }}>
                            <SkeletonBodyText lines={1} />
                        </div>
                    </InlineStack>
                    <div style={{ "width": "80px" }}>
                        <SkeletonBodyText lines={1} />
                    </div>
                </InlineStack>
                <BlockStack gap='300'>
                    <InlineStack align="space-between">
                        <div style={{ "width": "80px" }} className='skeleton-height-20'>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <div style={{ "width": "80px" }} className='skeleton-height-20'>
                            <SkeletonBodyText lines={1} />
                        </div>
                    </InlineStack>
                    <InlineStack align="space-between">
                        <div style={{ "width": "80px" }} className='skeleton-height-20'>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <div style={{ "width": "80px" }} className='skeleton-height-20'>
                            <SkeletonBodyText lines={1} />
                        </div>
                    </InlineStack>
                    <InlineStack align="space-between">
                        <div style={{ "width": "80px" }} className='skeleton-height-20'>
                            <SkeletonBodyText lines={1} />
                        </div>
                        <div style={{ "width": "80px" }} className='skeleton-height-20'>
                            <SkeletonBodyText lines={1} />
                        </div>
                    </InlineStack>
                </BlockStack>
            </BlockStack>
        </Card>
    )
}

export default ConnectedShopSkeleton