import {
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  Layout,
  Page,
  Text,
  useBreakpoints,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import "./settings.css";
import GeneralEtsySettings from "./components/GeneralEtsySettings";
import ProductManagement from "./../Onboarding/components/ProductManagement";
import OrderManagement from "./components/OrderManagement";
import AccountDetails from "./components/AccountDetails";
import Notifications from "./components/Notifications";
import ShopifyDetails from "./components/ShopifyDetails";
import { DI } from "Src/core";
import { DIProps, ObjI, ObjStrI, PaymentHistoryI } from "Src/Interface/@core";
import { settigsTabs } from "Src/Constant/SelectOptions";
import { useSettingsContext } from "./SettingsProvider";
import { apiUrls } from "Src/Constant/url";
import { saveAccountInfo } from "Src/Redux/Slices/dashboardSlice";
import { ToastAppBridge } from "../HelperComponents/Toast";
import {
  saveAccountDetails,
  saveLocationMap,
  saveNotifications,
  saveShopifyDetails,
} from "Src/Redux/Slices/settingsSlice";
import "../Onboarding/Onboarding.css";
import { regexExpressions } from "Src/Constants";
import ModalAppBridge from "../HelperComponents/ModalAppBridge";
import { isConfigChecked } from "Src/function";
import { getSavePayload, getSettingsErrors } from "./functions";

import WithPageHeaderSettingSkeleton from "./Skeleton/WithPageHeaderSettingSkeleton";
import Activator from "../Notification/Activator";
import { useSearchParams } from "react-router-dom";

const { getShops, getAccountInfo, getConfig, saveConfig } = apiUrls;

const Settings = (_props: DIProps) => {
  const {
    t,
    request: { GET, POST },
    dispatch,
  } = _props;
  const tabs = settigsTabs(t);
  const [searchParams] = useSearchParams();

  const {
    multiLocationEnabled,
    selectedTab,
    changeTab,
    config,
    updateConfig,
    isChange,
    discardModal,
    setIsChange,
    setDiscardModal,
    localConfig,
    updateLocalConfig,
    errors,
    updateErrors,
    removeError,
  } = useSettingsContext();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { mdUp } = useBreakpoints();

  const childElement: { [key: string]: JSX.Element } = {
    generalEtsySettings: <GeneralEtsySettings />,
    productManagement: (
      <ProductManagement
        config={localConfig}
        changeHandlerConfig={changeHandlerConfig}
        updateConfig={updateLocalConfig}
        errorsObj={errors}
        removeError={removeError}
        multiLocationEnabled={multiLocationEnabled}
      />
    ),
    orderManagement: (
      <OrderManagement
        config={localConfig}
        changeHandlerConfig={changeHandlerConfig}
      />
    ),
    accountDetails: <AccountDetails />,
    notifications: <Notifications />,
    shopDetails: <ShopifyDetails />,
  };

  /**
   * handles all the changes in config object
   * @param key name of key tobe updated
   * @param toBeToggled whether the key has to be toggled
   * @param value updated value
   * @param parentKey name of parent key in case of nested value
   */
  function changeHandlerConfig(
    key: string,
    toBeToggled: boolean = false,
    value?: string | ObjI | string[],
    parentKey?: string
  ) {
    !isChange && setIsChange(true);
    const numericKeys = ["currency_conversion_rate"];
    const childKeysMap: ObjStrI = {
      sync_product_enable: "sync-fields",
      enable_product_management_with_etsy: "etsy-sync-fields",
      enable_variation_management_with_etsy: "etsy-variation-sync-fields",
    };
    const childValuesMap: { [key: string]: ObjStrI } = {
      sync_product_enable: {
        title: "1",
        tags: "1",
        weight: "1",
        description: "1",
        shopify_videos: "1",
        price: "1",
        inventory: "1",
        images: "1",
        requires_shipping: "1",
      },
      enable_product_management_with_etsy: {
        etsy_title: "1",
        etsy_tags: "1",
        etsy_description: "1",
        etsy_material: "1",
        etsy_shipping_profile: "1",
        etsy_video: "1",
      },
      enable_variation_management_with_etsy: {
        etsy_price: "1",
        etsy_inventory: "1",
      },
    };
    const parentKeysMap: ObjStrI = {
      "sync-fields": "sync_product_enable",
      // "etsy-sync-fields": "enable_product_management_with_etsy",
      "etsy-variation-sync-fields": "enable_variation_management_with_etsy",
    };
    if (
      numericKeys.includes(key) &&
      !regexExpressions.NUMBER_CHECK_WITH_DOT.test(value as string)
    ) {
      return;
    }
    let tempValue = value;
    let prevValue;
    if (parentKey && typeof localConfig?.[parentKey] === "object") {
      prevValue = localConfig?.[parentKey]?.[key];
    } else {
      prevValue = localConfig?.[key];
    }
    if (toBeToggled && typeof prevValue !== "object") {
      tempValue = isConfigChecked(prevValue) ? "0" : "1";
    }
    const tempConfig = { ...localConfig };
    if (parentKey) {
      if (tempConfig[parentKey]) {
        Object.assign(tempConfig[parentKey], { [key]: tempValue });
      } else {
        Object.assign(tempConfig, {
          [parentKey]: { [key]: tempValue },
        });
      }
    } else {
      if (key in childKeysMap) {
        const childKey = childKeysMap[key];
        Object.assign(tempConfig, {
          [childKey]: tempValue === "0" ? {} : childValuesMap[key],
        });
      } else if (
        key in parentKeysMap &&
        Object.keys(tempValue as ObjI).length === 0
      ) {
        Object.assign(tempConfig, {
          [parentKeysMap[key]]: "0",
        });
      }
      Object.assign(tempConfig, { [key]: tempValue });
    }
    updateLocalConfig({ ...tempConfig });
  }

  const getConfigData = () => {
    setLoading(true);
    GET(getConfig)
      .then((res) => {
        if (res?.success) {
          if (res?.data?.model) {
            const tempConfig: any = { ...res?.data?.model };
            if (tempConfig?.vat_setting?.split_applied_on) {
              const { split_applied_on, ...vat_setting } =
                tempConfig.vat_setting;
              tempConfig.vat_setting = vat_setting;
              Object.assign(tempConfig, { split_applied_on });
            }
            // extracting currency data
            Object.assign(tempConfig, {
              shopify_currency: res?.data?.shopify_currency,
              etsy_currency: res?.data?.etsy_currency,
            });
            const keysToBeParsed = [
              "custom_setting",
              "locations_management",
              "taxes_included",
            ];
            keysToBeParsed.forEach((key) => {
              Object.assign(tempConfig, {
                [key]: tempConfig?.[key] ? JSON.parse(tempConfig?.[key]) : {},
              });
            });
            if (tempConfig?.metafield_namespaces) {
              Object.assign(tempConfig, {
                metafield_namespaces: Object.keys(
                  JSON.parse(tempConfig?.metafield_namespaces)
                ),
              });
            }
            if (tempConfig?.order_default_tags) {
              Object.assign(tempConfig, {
                order_default_tags: Object.keys(tempConfig?.order_default_tags),
              });
            }
            if (!tempConfig?.order_note_management) {
              Object.assign(tempConfig, {
                order_note_management: {
                  gift_message: "1",
                  message_from_buyer: "1",
                  default_note: "",
                },
              });
            }
            if (tempConfig?.product_options) {
              Object.assign(tempConfig, {
                product_options: tempConfig?.product_options.split(","),
              });
            }

            dispatch(saveLocationMap(res?.data?.all_saved_location ?? {}));
            updateConfig(
              tempConfig,
              isConfigChecked(res?.data?.is_multilocation)
            );
            updateLocalConfig(tempConfig);
          }
        } else {
          ToastAppBridge(res?.message, { isError: true });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getShopsData = () => {
    // WRITE CONDITION HERE TO EXECUTE CALL HERE OR NOT
    GET(getShops)
      .then((res) => {
        if (res.success && res?.data) {
          if (res?.data?.payment?.length > 0) {
            dispatch(
              saveAccountDetails({
                paymentHistory: res?.data?.payment.reduce(
                  (acc: PaymentHistoryI, ind_payment: any) => {
                    const paymentData: PaymentHistoryI = {
                      [ind_payment?.id ?? ""]: {
                        activated_on: ind_payment?.activated_on ?? "",
                        id: ind_payment?.id ?? "",
                        plan_name: ind_payment?.plan_name ?? "",
                        price: ind_payment?.payment_data?.price ?? "",
                        status: ind_payment?.status ?? "",
                        billing_on: ind_payment?.payment_data?.billing_on,
                        created_at: ind_payment?.payment_data?.created_at,
                        currency: ind_payment?.payment_data?.currency,
                        test: ind_payment?.payment_data?.test,
                        trial_days: ind_payment?.payment_data?.trial_days,
                        trial_ends_on: ind_payment?.payment_data?.trial_ends_on,
                        type: ind_payment?.payment_data?.type,
                        updated_at: ind_payment?.payment_data?.updated_at,
                        payment_type: ind_payment?.payment_type,
                      },
                    };
                    return {
                      ...acc,
                      ...paymentData,
                    };
                  },
                  {}
                ),
              })
            );
          }
          dispatch(
            saveNotifications({
              failed_order: `${
                res?.data?.email_subscriptions?.["Failed Order Notification"] ??
                "0"
              }`,
              order: `${
                res?.data?.email_subscriptions?.["Order Notification"] ?? "0"
              }`,
              subscription_plan: `${
                res?.data?.email_subscriptions?.["Subscription Plan"] ?? "0"
              }`,
              order_threshold: {
                On_Exceeding_80: `${
                  res?.data?.order_threshold?.["On_Exceeding_80"] ?? "0"
                }`,
                On_Exceeding_95: `${
                  res?.data?.order_threshold?.["On_Exceeding_95"] ?? "0"
                }`,
                On_Exceeding_100: `${
                  res?.data?.order_threshold?.["On_Exceeding_100"] ?? "0"
                }`,
              },
            })
          );
          if (res?.data?.shopify_details?.[0]) {
            dispatch(
              saveShopifyDetails({
                currency: res?.data?.shopify_details?.[0]?.currency,
                email:
                  res?.data?.email ?? res?.data?.shopify_details?.[0]?.email,
                owner_name: res?.data?.shopify_details?.[0]?.owner_name,
                preferred_time: res?.data?.Timezonevalue,
                shopify_country:
                  res?.data?.shopify_details?.[0]?.shopify_country,
                shopify_plan: res?.data?.shopify_details?.[0]?.shopify_plan,
                shopurl: res?.data?.shopify_details?.[0]?.shopurl,
                time_zone:
                  res?.data?.Timezonestatus ??
                  res?.data?.shopify_details?.[0]?.iana_timezone,
              })
            );
          }
        } else {
          ToastAppBridge(res?.message, { isError: true });
        }
      })
      .finally(() => {
        // set loader false here
      });
  };

  const fetchAccountInfo = () => {
    // WRITE CONDITION HERE TO EXECUTE CALL HERE OR NOT
    GET(getAccountInfo)
      .then((res) => {
        if (res?.success) {
          const { data } = res;
          dispatch(
            saveAccountInfo({
              activePlan: data?.active_plan,
              billingDate: data?.billing_date,
              activated_on: data?.activated_on,
              orderLimit: data?.order_limit,
              planOrderLimit: data?.plan_order_limit ? Number(data?.plan_order_limit) : null,
              planAmt: data?.plan_amount,
              duration: data?.duration.slice(0, -2),
              productLimit: data?.product_limit,
              ordersUsed: data?.order_consume_limit ?? 0,
              productsUsed: data?.uploadCount,
              clientInfo: data?.client_info,
              type: data?.type
            })
          );
        } else {
          ToastAppBridge(res?.message, { isError: true });
        }
      })
      .finally(() => {
        // set loader false here
      });
  };

  const saveConfigData = () => {
    const errorsObj = getSettingsErrors(localConfig, errors);
    if (Object.keys(errorsObj).length > 0) {
      updateErrors(errorsObj);
      return;
    }
    setSaveLoading(true);
    const payload = getSavePayload(localConfig, selectedTab);
    POST(saveConfig, payload)
      .then((res) => {
        if (res?.success) {
          ToastAppBridge(res?.message);
          setIsChange(false);
        } else {
          ToastAppBridge(res?.message, { isError: true });
        }
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  useEffect(() => {
    const querySelectedTab = searchParams.get("selectedTab");
    if (querySelectedTab && parseInt(querySelectedTab) !== selectedTab) {
      changeTab(parseInt(querySelectedTab));
    }
    getShopsData();
    fetchAccountInfo();
    getConfigData();
  }, []);

  return loading ? (
    <WithPageHeaderSettingSkeleton />
  ) : (
    <>
      <Box borderBlockEndWidth={mdUp ? "025" : "0"} borderColor="border">
        <div className="custom_layout_width">
          <Page fullWidth title={t("SETTINGS")} primaryAction={<Activator />} />
        </div>
      </Box>
      <div className="custom_layout_width">
        <Page fullWidth>
          <Layout>
            <Layout.Section>
              <div className="inte-Tab-outer settings-tab">
                <Box>
                  <Card padding={"400"}>
                    <ul className="tab-buttons">
                      {tabs.map((tab, index) => (
                        <li
                          key={tab._id}
                          className={index === selectedTab ? "active" : ""}
                        >
                          <Button
                            fullWidth
                            textAlign="left"
                            onClick={() => {
                              if (isChange) {
                                setDiscardModal({
                                  open: true,
                                  callbackFunc: () => {
                                    changeTab(index);
                                    setIsChange(false);
                                  },
                                });
                              } else changeTab(index);
                            }}
                            variant="tertiary"
                          >
                            {tab.label}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </Card>
                </Box>
                <div className="tab-content">
                  <BlockStack gap={"200"}>
                    {
                      childElement[
                        tabs[selectedTab]?.key ?? "generalEtsySettings"
                      ]
                    }
                    {selectedTab < 3 ? (
                      <div className="skeleton_page_header_spacing">
                        <InlineStack align="end">
                          <Button
                            loading={saveLoading}
                            onClick={saveConfigData}
                            variant="primary"
                          >
                            {t("SAVE")}
                          </Button>
                        </InlineStack>
                      </div>
                    ) : null}
                  </BlockStack>
                </div>
              </div>
            </Layout.Section>
          </Layout>
        </Page>
        <ModalAppBridge
          body={
            <Box padding={"400"}>
              <Text as="p">{t("WANT_TO_DISCARD_UNSAVE_CHANGES")} </Text>
            </Box>
          }
          footer={
            <>
              <button
                tone="critical"
                variant="primary"
                onClick={() => {
                  discardModal.callbackFunc();
                  if (selectedTab < 3) {
                    updateLocalConfig({ ...config });
                  }
                  setDiscardModal({ open: false, callbackFunc: () => {} });
                }}
              >
                {t("CLOSE_AND_DISCARD")}
              </button>
              <button
                onClick={() => {
                  setDiscardModal({ open: false, callbackFunc: () => {} });
                }}
              >
                {t("KEEP_EDITING")}
              </button>
            </>
          }
          id=""
          onHide={() =>
            setDiscardModal({ open: false, callbackFunc: () => {} })
          }
          open={discardModal.open}
          title={t("DISCARD_CHANGES")}
        />
      </div>
    </>
  );
};

export default DI(Settings);
